import React, { useState, useEffect } from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from 'types';
import axios from 'axios';
import { Alert } from 'AlertPicker';

interface Props extends PanelProps<SimpleOptions> {}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const [tiles, setTiles] = useState<Tile[]>([]);
  const [firstRun, setFirstRun] = useState<boolean>(true);
  const [currentAlertState, setCurrentAlertState] = useState<CurrentAlertState[]>([]);

  interface Tile {
    mqttKeys: MqttKey[];
    tileName: string;
    jsonError: boolean;
    dashboardId: string;
    cardColor: string;
    alertName: string;
  }

  interface MqttKey {
    displayName: string;
    key: string;
    topic: string;
    value: string;
    lastUpdated: number;
    clockColor: string;
  }

  interface CurrentAlertState {
    alertName: string;
    alertState: string;
  }

  useEffect(() => {
    parseMqttData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (firstRun) {
      parseMqttData();
    }
    setClockColor();
    const interval = setInterval(() => {
      setClockColor();
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tiles]);

  useEffect(() => {
    populateMqttKeys(options.alerts);
    getAndPopulateAlerts();
    const interval = setInterval(() => {
      getAndPopulateAlerts();
    }, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tiles.length <= 0) {
      return;
    }
    setAlertColors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAlertState]);

  const setClockColor = () => {
    //loop through all tiles
    let tmpTiles = [...tiles];
    for (let i = 0; i < tmpTiles.length; i++) {
      //loop through all mqtt keys in tile
      for (let j = 0; j < tmpTiles[i].mqttKeys.length; j++) {
        if (Date.now() - tmpTiles[i].mqttKeys[j].lastUpdated! > options.staleTime * 1000) {
          tmpTiles[i].mqttKeys[j].clockColor = 'grey';
        } else {
          tmpTiles[i].mqttKeys[j].clockColor = '#3d71aa';
        }
      }
    }
  };

  //parses mqtt data from the backend and updates the tiles with the new data
  const parseMqttData = () => {
    let topicData = data.series[0].fields.find((field) => field.name === 'Topic');
    let valueData = data.series[0].fields.find((field) => field.name === 'Value');
    let timeData = data.series[0].fields.find((field) => field.name === 'Time');
    //first run and subsequent runs are handled differently as upon initial load, all stored mqtt
    //data is sent and order is unknown, but on following runs, the latest data is always at the end of the array
    if (!firstRun) {
      //loop though all tiles
      let tmpTiles = [...tiles];
      for (let i = 0; i < tmpTiles.length; i++) {
        //loop through all mqtt keys in tile
        for (let j = 0; j < tmpTiles[i].mqttKeys.length; j++) {
          //compare mqtt key topic to topic the last entry in the array
          if (tmpTiles[i].mqttKeys[j].topic === topicData?.values.get(topicData.values.length - 1)) {
            //if topic matches, parse the json of the last value in valueData and set the value of the mqtt key to the value of the key
            let tmpValue = JSON.parse(valueData?.values.get(valueData.values.length - 1));
            //if the key is == to operating_status, change to text using setOperatingStatus
            if (tmpTiles[i].mqttKeys[j].key === 'operating_status') {
              tmpTiles[i].mqttKeys[j].value = setOperatingStatus(tmpValue[tmpTiles[i].mqttKeys[j].key]);
            } else {
            tmpTiles[i].mqttKeys[j].value = tmpValue[tmpTiles[i].mqttKeys[j].key];
            }
            tmpTiles[i].mqttKeys[j].lastUpdated = timeData?.values.get(timeData.values.length - 1);
          }
        }
      }
      setTiles(tmpTiles);
    } else {
      let tmpTiles = [...tiles];
      if (tmpTiles.length > 0) {
        setFirstRun(false);
      }
      //loop through all tiles
      for (let i = 0; i < tmpTiles.length; i++) {
        //loop through all mqtt keys in tile
        for (let j = 0; j < tmpTiles[i].mqttKeys.length; j++) {
          //find last occurence of topic in topicData, ignore undefined
          let lastOccurence = topicData?.values.toArray().lastIndexOf(tmpTiles[i].mqttKeys[j].topic);
          if (lastOccurence !== -1) {
            //if topic matches, parse the json of the last value in valueData and set the value of the mqtt key to the value of the key
            let tmpValue = JSON.parse(valueData?.values.get(lastOccurence!));

            //if the key is == to operating_status, change to text
            if (tmpTiles[i].mqttKeys[j].key === 'operating_status') {
              tmpTiles[i].mqttKeys[j].value = setOperatingStatus(tmpValue[tmpTiles[i].mqttKeys[j].key]);
            } else {

            tmpTiles[i].mqttKeys[j].value = tmpValue[tmpTiles[i].mqttKeys[j].key];
          }
          tmpTiles[i].mqttKeys[j].lastUpdated = timeData?.values.get(lastOccurence!);
          }

          //if no value is found, set value to 'no data'
          if (tmpTiles[i].mqttKeys[j].value === undefined) {
            tmpTiles[i].mqttKeys[j].value = 'no data';
          }

          //if no last updated is found, set last updated to 0
          if (tmpTiles[i].mqttKeys[j].lastUpdated === undefined) {
            tmpTiles[i].mqttKeys[j].lastUpdated = 0;
          }
        }
      }
    }
  };

  const setOperatingStatus = (status: string) => {
    console.log("status: " + status)
  switch (status.toString()) {
    case '0':
      return 'Stopped'
    case '1':
      return 'Run Program'
    case '2':
      return 'Hold Program'
    case '4':
      return 'Suspend Program'
    case '8':
      return 'Undefined'
    case '16':
      return 'Run Manual'
    case '32':
      return 'Hold Manual'
    case '64':
      return 'Undefined'
    case '128':
      return 'Undefined'
    default:
      return 'Undefined'
  }
  }

  const calculateCardposition = (index: number) => {
    const cardMargin = 10;
    let cardPerRow = Math.floor(width / (options.tileWidth + cardMargin));
    if (cardPerRow === 0) {
      cardPerRow = 1;
    }
    const row = Math.floor(index / cardPerRow);
    const column = index % cardPerRow;
    return {
      top: row * (options.tileHeight + cardMargin),
      left: column * (options.tileWidth + cardMargin),
    };
  };

  //on first load, populates tiles with mqtt keys, no data is populated yet
  const populateMqttKeys = (alerts: Alert[]) => {
    const checkValidJson = (json: string) => {
      try {
        JSON.parse(json);
      } catch (e) {
        return false;
      }
      return true;
    };

    const separateDisplayName = (fullName: string) => {
      let displayName = fullName.split(';')[1];
      return displayName;
    };

    const separateKeyName = (fullName: string) => {
      let displayName = fullName.split(';')[0];
      return displayName;
    };

    let tmpTiles = [...tiles];
    for (let i = 0; i < alerts.length; i++) {
      let tmpTile: Tile = {
        mqttKeys: [],
        tileName: alerts[i].tileName!,
        jsonError: true,
        dashboardId: alerts[i].dashboardName!,
        cardColor: 'grey',
        alertName: alerts[i].alertName!,
      };
      if (checkValidJson(alerts[i].mqttKeys!)) {
        const mqttKeys = JSON.parse(alerts[i].mqttKeys!);
        for (let j = 0; j < mqttKeys.keys.length; j++) {
          let tmpMqttKey: MqttKey = {
            displayName: separateDisplayName(mqttKeys.keys[j].key),
            key: separateKeyName(mqttKeys.keys[j].key),
            topic: mqttKeys.keys[j].topic,
            value: '',
            lastUpdated: 0,
            clockColor: 'grey',
          };
          tmpTile.mqttKeys.push(tmpMqttKey);
        }
        tmpTile.jsonError = false;
      }
      tmpTiles.push(tmpTile);
    }
    setTiles(tmpTiles);
  };

  const getAndPopulateAlerts = () => {
    axios
      .get('/api/prometheus/grafana/api/v1/alerts')
      .then((response) => {
        let tmpAlerts: CurrentAlertState[] = [];
        for (let i = 0; i < response.data.data.alerts.length; i++) {
          let tmpAlert: CurrentAlertState = {
            alertName: response.data.data.alerts[i].labels.alertname,
            alertState: response.data.data.alerts[i].state,
          };
          tmpAlerts.push(tmpAlert);
        }

        setCurrentAlertState(tmpAlerts);
      })
      .catch((error) => {});
  };

  const setAlertColors = () => {
    const setColor = (state: string) => {
      switch (state) {
        case 'Alerting (Error)':
          return 'grey';
        case 'Pending (Error)':
          //yellow
          return '#FAD34A';
        case 'Alerting':
          //red
          return '#E0226E';
        case 'Normal':
          //green
          return '#1B855E';
        default:
          return '#E0226E';
      }
    };

    let tmpTiles = [...tiles];
    for (let i = 0; i < tmpTiles.length; i++) {
      for (let j = 0; j < currentAlertState.length; j++) {
        if (tmpTiles[i].alertName === currentAlertState[j].alertName) {
          tmpTiles[i].cardColor = setColor(currentAlertState[j].alertState);
        }
      }
    }
    setTiles(tmpTiles);
  };

  //formats the last updated time to a readable format
  const formatKeyLastUpdated = (lastUpdated: number) => {
    let date = new Date(lastUpdated);
    let hours = date.getHours();
    let minutes = '0' + date.getMinutes();
    let seconds = '0' + date.getSeconds();
    let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    return 'Last Updated: ' + date.toLocaleDateString() + ' ' + formattedTime;
  };

  return (
    <div
      style={{
        padding: 10,
        position: 'relative',
        overflow: 'auto',
        height: height,
      }}
    >
      {tiles.map((tile, index) => {
        return (
          //eslint-disable-next-line
          <a href={tile.dashboardId}>
          <div
            style={{
              borderRadius: 5,
              padding: 10,
              position: 'absolute',
              top: calculateCardposition(index).top,
              left: calculateCardposition(index).left,
              width: options.tileWidth,
              height: options.tileHeight,
              backgroundColor: tile.cardColor,
              zIndex: 1,
              overflow: 'auto',
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                margin: '0 auto',
                marginBottom: 10,
                borderRadius: 5,
                textAlign: 'center',
                fontSize: options.fontSize,
                color: 'black',
                padding: 5,
                zIndex: 2,
              }}
            >
              {tile.tileName}
            </div>
            {tile.mqttKeys.map((mqttKey, index) => {
              return (
                <div
                  key={0}
                  style={{
                    backgroundColor: 'white',
                    marginBottom: 5,
                    borderRadius: 5,
                    display: 'flex',
                    width: '100%',
                    fontSize: options.fontSize,
                    color: 'black',
                    padding: 5,
                    zIndex: 2,
                  }}
                >
                  <div
                    style={{
                      backgroundColor: 'lightgrey',
                      flex: 1,
                      padding: 5,
                      borderRadius: 5,
                      overflow: 'auto',
                    }}
                  >
                    {mqttKey.displayName}
                  </div>
                  <div
                    style={{
                      backgroundColor: 'darkgrey',
                      borderRadius: 5,
                      padding: 5,
                      textAlign: 'right',
                      overflow: 'auto',
                      marginLeft: 10,
                      flex: 1,
                    }}
                  >
                    {mqttKey.value}
                  </div>
                  <div
                    title={formatKeyLastUpdated(mqttKey.lastUpdated)}
                    style={{
                      backgroundColor: tile.mqttKeys[index].clockColor,
                      borderRadius: 5,
                      padding: 5,
                      textAlign: 'right',
                      overflow: 'auto',
                    }}
                  >
                    {'\u23F1'}
                  </div>
                </div>
              );
            })}
          </div>
          </a>
        );
      })}
    </div>
  );
};
