import React, { useState, useEffect } from 'react';
//import { IconButton, Select, Input, Button} from '@grafana/ui';
import { SelectableValue, StandardEditorProps } from '@grafana/data';
import axios from 'axios';
import { IconButton, Select, InlineField, Input, TextArea } from '@grafana/ui';

export interface Alert {
  folderName: string | undefined;
  alertName: string | undefined;
  tileName: string | undefined;
  dashboardName: string | undefined;
  mqttKeys: string | undefined;
}

interface Dashboard {
  id: number;
  uid: string;
  title: string;
  url: string;
}

export const AlertPicker: React.FC<StandardEditorProps<Alert[]>> = ({ item, value, onChange }) => {
  const [allAlerts, setAllAlerts] = useState(Array<Alert>);
  const [allDashboards, setAllDashboards] = useState<Dashboard[]>();

  //get all alerts
  useEffect(() => {
    const getAlerts = async () => {
      axios
        .get('/api/prometheus/grafana/api/v1/alerts')
        .then((response) => {
          let tmpAlerts: Alert[] = [];
          for (let i = 0; i < response.data.data.alerts.length; i++) {
            tmpAlerts.push({
              folderName: response.data.data.alerts[i].labels.grafana_folder,
              alertName: response.data.data.alerts[i].labels.alertname,
              tileName: undefined,
              dashboardName: undefined,
              mqttKeys: undefined,
            });
          }
          setAllAlerts(tmpAlerts);
        })
        .catch((error) => {
          setTimeout(() => {
            getAlerts();
          }, 1000);
        });
    };

    //get all dashboards
    const getDashboards = async () => {
      axios
        .get('/api/search?query=%')
        .then((response) => {
          let tmpDashboards: Dashboard[] = [];
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].type === 'dash-db') {
              tmpDashboards.push({
                id: response.data[i].id,
                uid: response.data[i].uid,
                title: response.data[i].title,
                url: response.data[i].url,
              });
            }
          }
          setAllDashboards(tmpDashboards);
        })
        .catch((error) => {
          setTimeout(() => {
            getDashboards();
          }, 1000);
        });
    };

    getAlerts();
    getDashboards();
  }, []);

  //get all folders
  const getFolderNames = () => {
    let folderNames: Array<SelectableValue<string>> = [];
    for (let i = 0; i < allAlerts.length; i++) {
      //check if folderName is already in folderNames
      let found = false;
      for (let j = 0; j < folderNames.length; j++) {
        if (allAlerts[i].folderName === folderNames[j].label) {
          found = true;
        }
      }
      if (!found) {
        folderNames.push({ label: allAlerts[i].folderName, value: allAlerts[i].folderName });
      }
    }
    return folderNames;
  };

  //get all dashboards
  const getDashboardNames = () => {
    let dashboardNames: Array<SelectableValue<string>> = [];
    if (allDashboards !== undefined) {
      for (let i = 0; i < allDashboards.length; i++) {
        dashboardNames.push({ label: allDashboards[i].title, value: allDashboards[i].url });
      }
    }
    return dashboardNames;
  };

  //get all alerts in selected folder
  const getAlertNames = (index: number) => {
    let alertNames: Array<SelectableValue<string>> = [];
    if (value !== undefined && value.length > 0) {
      for (let i = 0; i < allAlerts.length; i++) {
        if (allAlerts[i].folderName === value[index].folderName) {
          alertNames.push({ label: allAlerts[i].alertName, value: allAlerts[i].alertName });
        }
      }
    }
    return alertNames;
  };

  //TODO: clear out alertNames when folderName is changed
  const updateFolderName = (folderName: SelectableValue, index: number) => {
    //if value is undefined, no tiles have been configured yet
    if (value !== undefined && value.length > 0) {
      let tmpValue = [...value];
      tmpValue[index].folderName = folderName.value;
      return tmpValue;
    } else {
      return [
        {
          folderName: folderName.value,
          alertName: '',
          tileName: undefined,
          dashboardName: undefined,
          mqttKeys: undefined,
          mqttTopic: undefined,
        },
      ];
    }
  };

  const updateAlertName = (alertName: SelectableValue, index: number) => {
    //if value is undefined, no tiles have been configured yet
    if (value !== undefined && value.length > 0) {
      let tmpValue = [...value];
      tmpValue[index].alertName = alertName.value;
      return tmpValue;
    } else {
      return [
        {
          folderName: undefined,
          alertName: alertName.value,
          tileName: undefined,
          dashboardName: undefined,
          mqttKeys: undefined,
          mqttTopic: undefined,
        },
      ];
    }
  };

  const updateTileName = (tileName: string, index: number) => {
    //if value is undefined, no tiles have been configured yet
    if (value !== undefined && value.length > 0) {
      let tmpValue = [...value];
      tmpValue[index].tileName = tileName;
      return tmpValue;
    } else {
      return [
        {
          folderName: undefined,
          alertName: undefined,
          tileName: tileName,
          dashboardName: undefined,
          mqttKeys: undefined,
          mqttTopic: undefined,
        },
      ];
    }
  };

  const updateDashboardName = (dashboardName: SelectableValue, index: number) => {
    //if value is undefined, no tiles have been configured yet
    if (value !== undefined && value.length > 0) {
      let tmpValue = [...value];
      tmpValue[index].dashboardName = dashboardName.value;
      return tmpValue;
    } else {
      return [
        {
          folderName: undefined,
          alertName: undefined,
          tileName: undefined,
          dashboardName: dashboardName.value,
          mqttKeys: undefined,
          mqttTopic: undefined,
        },
      ];
    }
  };

  const updateMqttKeys = (mqttKeys: string, index: number) => {
    let tmpValue = [...value];
    tmpValue[index].mqttKeys = mqttKeys;
    return tmpValue;
  };

  const checkValidJson = (json: string) => {
    try {
      JSON.parse(json);
    } catch (e) {
      return true;
    }
    return false;
  };

  const deleteConfigTile = (index: number) => {
    let tmpValue = [...value];
    tmpValue.splice(index, 1);
    return tmpValue;
  };

  const addConfigTile = (index: number) => {
    let tmpValue = [...value];
    tmpValue.splice(index + 1, 0, {
      folderName: undefined,
      alertName: undefined,
      tileName: undefined,
      dashboardName: undefined,
      mqttKeys: undefined,
    });
    return tmpValue;
  };

  const getFolderName = (alert: Alert) => {
    if (alert.folderName) {
      return { label: alert.folderName, value: alert.folderName };
    } else {
      return { label: 'Choose...', value: alert.folderName };
    }
  };

  const getAlertName = (alert: Alert) => {
    if (alert.alertName) {
      return { label: alert.alertName, value: alert.alertName };
    } else {
      return { label: 'Choose...', value: alert.alertName };
    }
  };

  const getDashboardName = (alert: Alert) => {
    if (alert.dashboardName) {
      if (allDashboards !== undefined) {
        for (let i = 0; i < allDashboards.length; i++) {
          if (allDashboards[i].url === alert.dashboardName) {
            return { label: allDashboards[i].title, value: alert.dashboardName };
          }
        }
      }
      return { label: alert.dashboardName, value: alert.dashboardName };
    } else {
      return { label: 'Choose...', value: alert.dashboardName };
    }
  };

  const getMqttConfig = (alert: Alert) => {
    if (alert.mqttKeys) {
      return alert.mqttKeys;
    } else {
      return 'undefined';
    }
  };

  const allConfigTiles = () => {
    //if value is undefined, no tiles have been configured yet, set up first tile
    if (value !== undefined && value.length > 0) {
      return value.map((alert, index) => {
        return (
          <>
            <div style={{ border: 'thin solid black', padding: '10px' }}>
              <InlineField label="Device Name" grow={true}>
                <Input
                  value={alert.tileName}
                  onChange={(e) => onChange(updateTileName(e.currentTarget.value, index))}
                />
              </InlineField>
              <InlineField label="Alert Folder Name" grow={true}>
                <Select
                  options={getFolderNames()}
                  onChange={(folderName) => onChange(updateFolderName(folderName, index))}
                  value={getFolderName(alert)}
                />
              </InlineField>
              <InlineField label="Alert Name" grow={true}>
                <Select
                  options={getAlertNames(index)}
                  onChange={(alertName) => onChange(updateAlertName(alertName, index))}
                  value={getAlertName(alert)}
                />
              </InlineField>
              <InlineField label="Dashboard Name" grow={true}>
                <Select
                  options={getDashboardNames()}
                  onChange={(dashboardName) => onChange(updateDashboardName(dashboardName, index))}
                  value={getDashboardName(alert)}
                />
              </InlineField>
              <TextArea
                placeholder='
              {
                "keys": [
                   {
                      "key": "test",
                      "topic": "test"
                   },
                   {
                      "key": "test",
                      "topic": "test"
                   }
                ]
             }'
                onChange={(e) => onChange(updateMqttKeys(e.currentTarget.value, index))}
                value={getMqttConfig(alert)}
                invalid={checkValidJson(getMqttConfig(alert))}
              ></TextArea>
              <IconButton
                name="minus"
                onClick={() => {
                  onChange(deleteConfigTile(index));
                }}
              />
              <IconButton
                name="plus"
                onClick={() => {
                  onChange(addConfigTile(index));
                }}
              />
            </div>
            <br></br>
          </>
        );
      });
    } else {
      return (
        <div style={{ border: 'thin solid black', padding: '10px' }}>
          <InlineField label="Device Name" grow={true}>
            <Input value={''} onChange={(e) => onChange(updateTileName(e.currentTarget.value, 0))} />
          </InlineField>
          <InlineField label="Alert Folder Name" grow={true}>
            <Select
              options={getFolderNames()}
              onChange={(folderName) => onChange(updateFolderName(folderName, 0))}
              value={getFolderName({
                folderName: undefined,
                alertName: '',
                tileName: undefined,
                dashboardName: undefined,
                mqttKeys: undefined,
              })}
            />
          </InlineField>
          <InlineField label="Alert Name" grow={true}>
            <Select
              options={getAlertNames(0)}
              onChange={(alertName) => onChange(updateAlertName(alertName, 0))}
              value={getAlertName({
                folderName: undefined,
                alertName: '',
                tileName: undefined,
                dashboardName: undefined,
                mqttKeys: undefined,
              })}
            />
          </InlineField>
          <InlineField label="Device Dashboard" grow={true}>
            <Select
              options={getDashboardNames()}
              onChange={(dashboardName) => onChange(updateDashboardName(dashboardName, 0))}
              value={getDashboardName({
                folderName: undefined,
                alertName: '',
                tileName: undefined,
                dashboardName: undefined,
                mqttKeys: undefined,
              })}
            />
          </InlineField>
        </div>
      );
    }
  };

  return <>{allConfigTiles()}</>;
};
