import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { SimplePanel } from './SimplePanel';
import { AlertPicker } from 'AlertPicker';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions(builder => {
  return builder
  .addSliderInput({
    path: 'staleTime',
    name: 'Stale Time',
    defaultValue: 10,
    settings: {
      min: 1,
      max: 600,
      step: 1,
    },
  })
  .addSliderInput({
    path: 'tileWidth',
    name: 'Tile Width',
    defaultValue: 100,
    settings: {
      min: 100,
      max: 500,
      step: 1,
    },
  }).addSliderInput({
    path: 'tileHeight',
    name: 'Tile Height',
    defaultValue: 100,
    settings: {
      min: 100,
      max: 500,
      step: 1,
    },
  })
  .addSliderInput({
    path: 'fontSize',
    name: 'Font Size',
    defaultValue: 10,
    settings: {
      min: 10,
      max: 100,
      step: 1,
    },
  })
    .addCustomEditor({
      id: 'alert',
      path: 'alerts',
      name: 'Tiles',
      description: 'Configure tile settings.',
      editor: AlertPicker,
    })

});
